import {
  checkDetailsStepConfig,
  confirmBookingStepConfig,
  getSteps,
  maintenanceTypeStepConfig,
  malfunctionsStepConfig,
  selectSupplierStepConfig,
  specialInstructionsStepConfig,
  thankYouStepConfig,
} from 'mlp-client/src/features-configuration/default/maintenance'
import {
  AdditionalServices,
  AdditionalServicesLabelReplacement,
  MaintenanceType,
  StepNames,
} from 'mlp-client/src/flows/maintenance/enums'
import { MyLeaseplanFeature } from 'mlp-client/src/features-configuration/types'
import { MaintenanceFlow } from 'mlp-client/src/flows/maintenance/components'
import {
  MaintenanceFlowConfiguration,
  Steps,
} from 'mlp-client/src/flows/maintenance/types'
import { AddressTypes } from 'mlp-client/src/user/types'
import { Options } from 'mlp-client/src/form/types'

const maintenanceTypeOptionLu: Options = [
  {
    value: MaintenanceType.Regular,
    title: 'myLeaseplan.maintenance.steps.selectMaintenanceType.regular',
    dataE2eId: MaintenanceType.Regular,
  }
]

const allSteps: Steps = {
  [StepNames.SELECT_MAINTENANCE_TYPE]: {
    ...maintenanceTypeStepConfig,
    stepConfig: {
      ...maintenanceTypeStepConfig.stepConfig,
      replaceLabels: {
        [AdditionalServices.COLLECT_AND_RETURN]:
          AdditionalServicesLabelReplacement.PICKUP_WORKPLACE,
      },
      options: maintenanceTypeOptionLu
    },
  },
  [StepNames.SELECT_MALFUNCTIONS]: malfunctionsStepConfig,
  [StepNames.SPECIAL_INSTRUCTIONS]: specialInstructionsStepConfig,
  [StepNames.SELECT_SUPPLIER]: selectSupplierStepConfig,
  [StepNames.CONFIRM_BOOKING]: {
    ...confirmBookingStepConfig,
    stepConfig: {
      ...confirmBookingStepConfig.stepConfig,
      dateLocationEditStep: StepNames.SELECT_SUPPLIER,
    },
  },
  [StepNames.CHECK_CONTACT_DETAILS]: checkDetailsStepConfig,
  [StepNames.THANK_YOU]: thankYouStepConfig,
}

export const maintenanceConfig: MyLeaseplanFeature<MaintenanceFlowConfiguration> = {
  enabled: true,
  view: MaintenanceFlow,
  configuration: {
    getSteps: getSteps({
      isLocationStepDisabled: true,
      isPickupAddressDefined: true,
    }),
    allSteps,
    defaultAddressType: AddressTypes.WORK,
  },
}
