// These constants are taken from '.env' on local deployment, and from the various listed sources on AWS deployment.
// Note that there is some code duplication for each constant, but by using the interface ServerEnv and the typed
// processEnv, any typos, omissions, or extraneous constants/types will be statically detected.

export interface ServerEnv {
  //SERVICES API ROUTES
  readonly IDENTITY_SERVICE_API: string
  readonly BOOKING_SERVICE_API: string
  readonly CONTRACT_SERVICE_API: string
  readonly REPORTING_SERVICE_API: string
  // Declared in apps/mlp-server/deploy/config/runtime-env-*.yml.j2 files under env_vars:
  readonly LEGACY_MLP_REDIRECT: string
  readonly CONTENT_API: string
  readonly SITECORE_API: string
  readonly SITECORE_API_KEY: string

  readonly IDENTITY_API: string
  readonly IDENTITY_AUDIENCE: string
  readonly IDENTITY_CLIENT_ID: string
  readonly IDENTITY_CLIENT_ID_MOBILE: string

  readonly DD_DEBUG: boolean
  readonly DD_AGENT_HOSTNAME: string
  readonly DD_STATSD_HOSTNAME: string
  readonly DD_SERVICE: string
  readonly DD_ENV: string

  readonly ANDROID_INSTALL_APP_URL: string
  readonly IOS_INSTALL_APP_URL: string
  readonly ONETRUST_ENDPOINT: string
  readonly ENABLE_MY_LEASEPLAN: string

  // Declared in apps/mlp-server/deploy/config/runtime-env-*.yml.j2 files under secret_env_vars:
  // NOTE: The client uses the webpack.DefinePlugin for git information, not these env variables.
  readonly GIT_SHA: string
  readonly GIT_BRANCH_SLUG: string

  // Provided by node:
  readonly NODE_ENV: string

  // Provided on Docker container launch:
  readonly HOST: string
  readonly PORT: string

  // Local use only:
  readonly DEV_LOG_CURL_REQUESTS: boolean

  readonly GOOGLE_MAPS_API_KEY: string
  readonly GTM_ID: string
  readonly GTM_AUTH: string
  readonly GTM_PREVIEW: string

  readonly PREVIEW_FEATURES: string
  readonly API_TOKEN: string
  readonly PLATFORM_URL: string

  readonly FAVICON_NAME: string
  readonly CSP_HEADER_VALUE: string
}

// For safety, narrow process.env to the used keys.
const processEnv: Record<keyof ServerEnv, string> = (process.env || {}) as any

export const serverEnv: ServerEnv = {
  LEGACY_MLP_REDIRECT: processEnv.LEGACY_MLP_REDIRECT,
  CONTENT_API: processEnv.CONTENT_API,
  SITECORE_API: processEnv.SITECORE_API,
  SITECORE_API_KEY: processEnv.SITECORE_API_KEY,

  DD_DEBUG: processEnv.DD_DEBUG === 'true',
  DD_AGENT_HOSTNAME: processEnv.DD_AGENT_HOSTNAME || 'localhost',
  DD_STATSD_HOSTNAME: processEnv.DD_STATSD_HOSTNAME || 'localhost',
  DD_SERVICE: processEnv.DD_SERVICE,
  // Append branch-name slug for unique preview envs, see https://leaseplan-digital.atlassian.net/browse/FCQ-403
  DD_ENV:
    processEnv.DD_ENV === 'preview'
      ? `${processEnv.DD_ENV}_${processEnv.GIT_BRANCH_SLUG}`
      : processEnv.DD_ENV,

  IDENTITY_API: processEnv.IDENTITY_API,
  IDENTITY_AUDIENCE: processEnv.IDENTITY_AUDIENCE,
  IDENTITY_CLIENT_ID: processEnv.IDENTITY_CLIENT_ID,
  IDENTITY_CLIENT_ID_MOBILE: processEnv.IDENTITY_CLIENT_ID_MOBILE,

  ENABLE_MY_LEASEPLAN: processEnv.ENABLE_MY_LEASEPLAN,
  ANDROID_INSTALL_APP_URL: processEnv.ANDROID_INSTALL_APP_URL,
  ONETRUST_ENDPOINT: processEnv.ONETRUST_ENDPOINT,
  IOS_INSTALL_APP_URL: processEnv.IOS_INSTALL_APP_URL,

  NODE_ENV: processEnv.NODE_ENV,

  HOST: processEnv.HOST || '0.0.0.0',
  PORT: processEnv.PORT || '3000',

  GIT_SHA: processEnv.GIT_SHA?.slice(0, 8) ?? null, // Will be null on local ts-node deployments.
  GIT_BRANCH_SLUG: processEnv.GIT_BRANCH_SLUG,

  DEV_LOG_CURL_REQUESTS: processEnv.DEV_LOG_CURL_REQUESTS === 'true',

  GOOGLE_MAPS_API_KEY: processEnv.GOOGLE_MAPS_API_KEY,
  GTM_ID: processEnv.GTM_ID,
  GTM_AUTH: processEnv.GTM_AUTH,
  GTM_PREVIEW: processEnv.GTM_PREVIEW,
  PREVIEW_FEATURES: processEnv.PREVIEW_FEATURES,
  IDENTITY_SERVICE_API: processEnv.IDENTITY_SERVICE_API,
  BOOKING_SERVICE_API: processEnv.BOOKING_SERVICE_API,
  REPORTING_SERVICE_API: processEnv.REPORTING_SERVICE_API,
  CONTRACT_SERVICE_API: processEnv.CONTRACT_SERVICE_API,
  PLATFORM_URL: processEnv.PLATFORM_URL,

  API_TOKEN: processEnv.API_TOKEN,
  FAVICON_NAME: processEnv.FAVICON_NAME,
  CSP_HEADER_VALUE: processEnv.CSP_HEADER_VALUE,
}
